.heading {
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
}

.description {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.grid > *:nth-child(n + 7) {
  display: none;
}

@media (min-width: theme('screens.md')) {
  .heading {
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.96px;
  }

  .description {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}

@media (min-width: theme('screens.lg')) {
  .grid > *:nth-child(n + 7) {
    display: block;
  }

  .grid > *:nth-child(n + 10) {
    display: none;
  }
}

@media (min-width: theme('screens.xxxxl')) {
  .grid > *:nth-child(n + 7) {
    display: block;
  }

  .grid > *:nth-child(n + 10) {
    display: block;
  }

  .grid > *:nth-child(n + 13) {
    display: none;
  }
}

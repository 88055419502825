.wrapper {
  @apply order-1 text-canvas-300;
}

.wrapper[data-size='small'] {
  @apply w-4 h-4;
}

.wrapper[data-size='medium'] {
  @apply w-5 h-5;
}

.wrapper[data-size='large'] {
  @apply w-6 h-6;
}

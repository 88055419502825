.root {
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
}

@media (min-width: theme('screens.md')) {
  .root {
    font-size: 72px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -1.44px;
  }
}

.wrapper {
  @apply order-4 text-gray-500 line-through lg:mr-1 lg:order-3 highlight;
}

.wrapper[data-size='small'] {
  @apply text-600 leading-300;
}

.wrapper[data-size='medium'] {
  @apply autoType800;
}

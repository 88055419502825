.title {
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

:global(.swiper-slide.swiper-slide-active) .description {
  @apply visible;
}

:global(.swiper-slide:not(.swiper-slide-active)) .description {
  @apply invisible;
}

.wrapper {
  @apply order-5 ml-1 text-gray-800 text;
}

.wrapper[data-size='small'] {
  @apply text-200 leading-200;
}

.wrapper[data-size='medium'] {
  @apply autoType400;
}

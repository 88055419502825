.nationalParksSwiper :global(.swiper-slide.swiper-slide-active) {
  @apply scale-100;
}

.nationalParksSwiper :global(.swiper-slide:not(.swiper-slide-active)) {
  @apply scale-90;
}

@screen lg {
  .nationalParksDesktopCarousel > div > div > div > p {
    @apply text-750 text-gray-800;
  }

  .nationalParksDesktopCarousel > div > div > div > p > span {
    @apply !text-gray-800;
  }

  .nationalParksDesktopCarousel > div > div {
    @apply !pb-8;
  }
}

.cardImageContainer::before {
  content: '';
  z-index: 1;
  @apply absolute rounded-2xl inset-0 top-[20%] pointer-events-none;
}

.cardImageContainer.odnCard::before {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 54.5%);
}

.cardImageContainer.nationalParkCard::before {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
}

:global(.swiper-slide.swiper-slide-active) .destinationCardText {
  @apply visible;
}

:global(.swiper-slide:not(.swiper-slide-active)) .destinationCardText:not(.inactiveDescription) {
  @apply invisible;
}

.root {
  @apply pb-10 lg:pb-20 last-of-type:pb-0 empty:hidden;
}

.root :global(.section-animated-block) {
  @apply translate-y-0 opacity-100;
}

.root :global(.section-animated-block)[data-to-be-animated='true'] {
  @apply transition-[opacity,transform] duration-700 ease-in-out;
}

.root :global(.section-animated-block)[data-animation-triggered='false'] {
  @apply translate-y-[200px] opacity-0;
}

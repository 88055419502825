.title {
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
}

@media (min-width: theme('screens.md')) {
  .title {
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.96px;
  }
}

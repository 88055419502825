.container {
  @apply contents;
}

.price {
  @apply order-2 mr-1 highlight;
}

.period {
  @apply order-4 text-gray-900 text;
}

.container[data-size='small'] .price {
  @apply text-600 leading-300;
}

.container[data-size='small'] .period {
  @apply text-200 leading-200;
}

.container[data-size='medium'] .price {
  @apply autoType800;
}

.container[data-size='medium'] .period {
  @apply autoType400;
}
